.email-form {
  width: 50%;
  height: 400px;
  overflow-y: scroll;
  margin-top: 100px;
  margin-left: auto;
  margin-right: auto;

  .button-email {
    color: white;
    background-color: $CelesteTheia;
    margin-top: 30px;
  }

  .email-inputs {
    margin-left: 10px;
    margin-top: 20px;
    margin-right: 10px;
    color: $grayColor;
  }

  .form-select {
    margin-top: 10px;
  }

  select {
    border: solid 1px $GreenTheia;
    background: white;
    height: 30px;
    margin-left: 5px;
  }
}
