.user-card {
  display: contents;

  .img-card {
    height: 100px;
    padding: 6px;
  }

  .img-wrapper {
    width: 100px;
    height: 100px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 50%;

    /* padding: 11px; */
    border: solid 1px $CelesteTheia;
    margin-bottom: 25px;
  }
}

.shadow-v {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.6) !important;
}

.display-none {
  display: none;
}

.one-column {
  width: 25%;
}

.two-column {
  width: 50%;
}

.three-column {
  width: 75%;
}

.four-column {
  width: 100%;
}

.container {
  display: flex;
}

.recharts-wrapper {
  width: auto !important;
  height: auto !important;
}

.dashboard-wrapper {
  .container {
    display: block;
  }

  .no-margin-bottom {
    margin-bottom: 0;
  }
}

.pending-badge {
  color: $pendingColor;
  font-weight: bold;
}

.success-badge {
  color: $GreenTheia;
  font-weight: bold;
}
