body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f9f9fc !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

// Here we will import all unique css
@import "./styles//colors.scss";
@import "./App.scss";
@import "./components//NavigationBar/navigationBar.scss";
@import "./components/Card/card.scss";
@import "./components/DropZone//dropzone.scss";
@import "./modules//Login/login.scss";
@import "./components//Buttons/buttons.scss";
@import "./components/GenericCard/genericCard.scss";
@import "./components//Alert/alert.scss";
@import "./modules/EmailWebSite//emailForm.scss";
@import "./modules//Dashboard/dashboardcards.scss";
