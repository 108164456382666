.modal-parent {
  position: absolute;
  z-index: 20;
  background-color: rgba(0, 0, 0, 0.6) !important;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  text-align: -webkit-center;
  bottom: 0;
}

.modal-child {
  width: fit-content;
  height: fit-content;
  margin-top: 95px;
}

.button-x-div {
  text-align: right;
  padding: 28px;
}

.button-x {
  background-color: transparent;
  border: none;
  font-size: 15px;
  color: $GrisTheia;
}

.button-div {
  margin-top: 50px;
  text-align: right;
  padding: 28px;
}

.children-container {
  margin-left: 100px;
  margin-right: 100px;
}
