.header {
  margin-bottom: 0px;

  .bg-white {
    background-color: $white;
  }

  a:hover {
    text-decoration: none;
  }

  @media only screen and (min-width: 780px) {
    .height-header {
      height: 100px;
    }
  }

  box-shadow: 0 0 5px 0 $CelesteTheia;

  .nav-link-color {
    color: $GrisTheia !important;
  }

  .nav-link-color:hover {
    color: $AzulTheia !important;
  }

  .flex-display {
    align-items: center;
    display: flex;
    font-weight: 300;
  }

  .img-logo {
    width: 108px;
    height: auto;
  }

  .nav-links {
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 14px;
  }
}
