$funGreen: #026639;
$yellowOrange: #FCB040;
$schooner: #938886;
$white: #FFFFFF;
$bgColorSite: #f9f9fc;
$grayColor: #6c7293;
$blackColor: black;
$AzulTheia: #4DA2DC;
$GrisTheia: #5B5A5B;
$CelesteTheia: #4FA8A5;
$GreenTheia: #95BB47;
$pendingColor: rgb(255, 184, 34);
