.card {
  border: none !important;
  box-shadow: 0 0 10px 0 rgba(82, 63, 105, 0.1);
  width: fit-content;
  height: 380px;
  padding: 30px;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;

  span {
    font-size: 15px;
  }

  h5 {
    color: #5B5A5B !important;
    font-size: 17px;
    align-items: center;
    padding: 0px;
    display: flex;
  }

  h6 {
    color: #5B5A5B !important;
    font-size: 16px;
  }

  .card-container {
    border: none;
    box-shadow: none;
    margin-top: auto;
    margin-bottom: auto;
  }

  text-align: center;
  border: none;

  // .card-img {
  //   width: 84px;
  //   margin-left: auto;
  //   margin-right: auto;
  // }

  // .card-button {
  //   color: white !important;
  //   background-color: $yellowOrange;
  //   color: $yellowOrange;
  //   border-color: $yellowOrange;
  // }
}

.card:hover {
  box-shadow: 0 0 10px 0 rgba(82, 63, 105, 0.4);
}

.row-margin-bottom {
  margin: 3%;
}

.statistics-boxes {
  border-bottom: solid 1px $CelesteTheia;
  padding: 2px;
  margin: 5px;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}
