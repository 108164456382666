.login-background {
  width: 100%;
  background-size: cover;
  height: 100vh;
}

.login-component {
  position: absolute !important;
  margin: auto;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 500px;
  height: 480px;
  overflow: auto;
  border: solid 2px $CelesteTheia;
  background-color: rgba(255, 255, 255, 0.8) !important;

  &.card {
    box-shadow: 0 0 10px 0 rgba(82, 63, 105, 0.4);
  }

  @media only screen and (max-width: 600px) {
    max-width: 100%;
    max-height: 350px;
  }

  .loading-div {
    position: absolute;
    margin-top: -134px;
    margin-left: 10px;
  }

  .img-login {
    width: 168px;
    height: auto;
    margin-top: 0px;
  }

  .form-login {
    margin-top: 30px;
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    font-size: 17px;
    font-weight: 400;
    color: $GrisTheia;

    .form-control {
      color: $GrisTheia !important;
    }
  }

  .button-link {
    color: #FFFFFF;
    background-color: $AzulTheia;
    border: solid 2px $AzulTheia;

    /* border-radius: 5%; */
    height: 45px;
    width: 100%;
    font-size: 16px;
    font-weight: 600;
  }

  .div-button-form {
    padding-top: 25px;
  }
  .forgot-password{ 
    padding-top: 10px;
    font-size: 15px;
    text-decoration: underline;
  }
  .accessRequest{
    padding-top: 15px;
    font-size: 15px;
    text-decoration: underline;
  }
}
