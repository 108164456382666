.celeste-theia-button {
  color: $white;
  background-color: $CelesteTheia;
  border: solid 2px $CelesteTheia;
  border-radius: 5%;
  height: 42px;
  width: 100%;
  font-size: 16px;
  font-weight: 500;
  max-width: 100px;
}

.button-properties {
  display: inline-flex;
  align-items: center;

  // padding-left: 6px;
}

.material-icons {
  font-size: 20px;
  padding-right: 2px;
}

.white-icon-button {
  border: none;
  background-color: transparent;
  color: white;
  position: absolute;
}
