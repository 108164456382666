.App-header {
  background-color: $white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: $funGreen;
}

.background-theiatrack {
  // background-image: linear-gradient(to right, $GreenTheia, $funGreen) !important;
  background-color: white !important;
}

.no-login-view-header {
  text-align: center;
  margin-top: 15px;
  color: #5b5a5b;
  margin-bottom: 1px;
  font-size: 18px;
}

.centered-navbar-text {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  font-weight: 500;
  right: 29px;
  top: 67px;
  color: #5B5A5B;
  text-align: center;
}

.absolute-icon {
  margin-top: 2.2px;
  position: absolute;
  margin-left: -22px;
  color: #f82b60;
}

.nav-link-color {
  .wlogo {
    width: 40px;
  }
}

@media screen and (max-width: 762px) {
  .centered-navbar-text {
    position: inherit;
    margin-left: 31px;
    display: block;
    margin-right: auto;
    font-size: 13px;
  }
}
